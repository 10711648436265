import { useState, useEffect } from "react";
import sliderImg1 from "../assets/home/1.jpeg";
import sliderImg2 from "../assets/home/2.jpeg";
import sliderImg3 from "../assets/home/3.jpeg";
import sliderImg4 from "../assets/home/4.jpeg";

const slides = [
  {
    id: 1,
    image: sliderImg1,
    text: "1 in 4 YOUNG ADULT'S fall for online scam.",
  },
  {
    id: 2,
    image: sliderImg2,
    text: "EVERY SECOND, A YOUNG ADULT'S ONLINE ACCOUNT IS HACKED.",
  },
  {
    id: 3,
    image: sliderImg3,
    text: "HIGH USE OF SOCIAL MEDIA LEADING TO ANXIETY & DEPRESSION",
  },
  {
    id: 4,
    image: sliderImg4,
    text: "LOW EMPLOYMENT DUE TO SKILLS GAPS IN USING BASIC SOFTWARES",
  },
];

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 3000); // Change slide every 3 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative w-full h-[350px] overflow-hidden">
      <div
        className="flex transition-transform duration-700"
        style={{
          transform: `translateX(-${currentSlide * 100}%)`,
        }}
      >
        {slides.map((slide) => (
          <div key={slide.id} className="w-full h-[350px] flex-shrink-0">
            <img
              src={slide.image}
              alt={slide.text}
              className="w-full h-full object-cover"
            />
            <div className="absolute bottom-0 bg-black text-lg md:text-xl text-white text-center uppercase w-full px-4 pt-4 pb-10">
              {slide.text}
            </div>
          </div>
        ))}
      </div>
      {/* Indicators */}
      <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex gap-2">
        {slides.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentSlide(index)}
            className={`h-3 w-3 rounded-full ${
              index === currentSlide ? "bg-white" : "bg-gray-400"
            }`}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default Slider;
