import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import avatar from "../assets/avatar.png";
import { PACKAGES, FEATURES } from "../data/packageData";

import "../styles/packages.css";

export default function PackagesPage() {
  useEffect(() => {
    const container = document.getElementById("scrollableTable");
    if (!container) return;

    const animateScroll = () => {
      container.scrollTo({ left: 100, behavior: "smooth" });
      setTimeout(() => {
        container.scrollTo({ left: 0, behavior: "smooth" });
      }, 700);
    };

    setTimeout(animateScroll, 1000);
  }, []);

  return (
    <div>
      <header>
        <div className="header">
          <Link to="/">
            <img src={avatar} alt="Company Logo" className="header-logo" />
          </Link>

          {/* Desktop header content */}
          <div className="header-content d-none d-md-block">
            <div className="coming-soon-badge">Coming soon!</div>
            <h1>Unlock Expert Digital Literacy at Unbeatable Prices</h1>
            <h2>Start Safeguarding Your Child's Future Today!</h2>
          </div>

          <Link to="/SignUp" className="header-signup-button">
            <button className="sign-in-button">Sign Up</button>
          </Link>
        </div>

        {/* Mobile header content */}
        <div className="welcome-content d-md-none">
          <div className="heading-mobile">
            <div className="coming-soon-badge">Coming soon!</div>
            <h1>Unlock Expert Digital Literacy at Unbeatable Prices</h1>
            <h2>Start Safeguarding Your Child's Future Today!</h2>
          </div>
        </div>
      </header>
      <div className="digital-literacy-container">
        {/* Desktop table */}
        <table className="package-table d-none d-lg-table">
          <thead>
            <tr>
              <th>Package</th>
              {PACKAGES.map((pkg, index) => (
                <th key={index}>{pkg.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {FEATURES.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td className="package-label">
                  <span
                    className={
                      row.title === "Subscription" ? "subscription-badge" : ""
                    }
                  >
                    {row.title}
                  </span>
                </td>
                {PACKAGES.map((pkg, pkgIndex) => (
                  <td key={pkgIndex}>
                    <PackageContent content={pkg[row.field]} />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>

        {/* Mobile grid table */}
        <div className="grid-table-container d-lg-none" id="scrollableTable">
          <div className="grid-table">
            <div className="grid-table-header sticky-column bg-purple-cell">
              Package
            </div>
            {PACKAGES.map((pkg, index) => (
              <div key={index} className="grid-table-header">
                {pkg.name}
              </div>
            ))}
          </div>
          <div className="grid-table">
            {FEATURES.map((row, rowIndex) => (
              <React.Fragment key={rowIndex}>
                <div className="grid-table-cell sticky-column bg-green-cell">
                  <div
                    style={{
                      backgroundColor:
                        row.title === "Subscription" ? "#00AF27B0" : "white",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    {row.title}
                  </div>
                </div>
                {PACKAGES.map((pkg, pkgIndex) => (
                  <div key={pkgIndex} className="grid-table-cell">
                    <div className="package-details">
                      {Array.isArray(pkg[row.field]) ? (
                        <ul>
                          {pkg[row.field].map((item, itemIndex) => (
                            <li key={itemIndex}>{item}</li>
                          ))}
                        </ul>
                      ) : (
                        <p>{pkg[row.field]}</p>
                      )}
                    </div>
                  </div>
                ))}
              </React.Fragment>
            ))}
          </div>
        </div>

        <footer className="footer">
          <p>
            Check out our <Link to="/SignIn">Quizzes</Link>, Play, Win and Learn
          </p>
        </footer>
      </div>
    </div>
  );
}

// Helper component for package content
const PackageContent = ({ content }) => {
  if (Array.isArray(content)) {
    return (
      <ul>
        {content.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    );
  }
  return <p>{content}</p>;
};
