import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import avatar from "../assets/avatar.png";

import "../styles/toggle.css";

export default function PaidUserControlPage() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isPwdVisible, setIsPwdVisible] = useState(false);
  const [users, setUsers] = useState([]);

  const fetchUsersList = () => {
    return axios
      .get(`/users`, {
        headers: {
          "ngrok-skip-browser-warning": true,
        },
      })
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.error("Error fetching users list:", error);
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const username = formData.get("username");
    const password = formData.get("password");

    if (username.toLowerCase() === "captain" && password === "@Diginaut91") {
      fetchUsersList();
      setIsAuthenticated(true);
    } else {
      alert("Invalid credentials");
    }
  };

  const handlePaidToggle = async (email, isPaid) => {
    try {
      await axios.put(`/user/update`, {
        email,
        isPaid: !isPaid,
      });
      fetchUsersList();
    } catch (error) {
      console.error("Error updating user paid status:", error);
      alert("Failed to update user status");
    }
  };

  if (isAuthenticated) {
    return (
      <div className="px-8 py-4">
        <div className="flex justify-center mb-4">
          <Link to="/">
            <img src={avatar} alt="logo" className="w-16 h-16" />
          </Link>
        </div>
        <h2 className="text-2xl mb-4 font-semibold text-gray-800">
          Users List
        </h2>
        <div className="bg-white/30 p-4 rounded-lg">
          <table className="border-collapse border-[1px] border-gray-300 [&_th]:border-[1px] [&_td]:border-[1px] [&_th]:border-gray-300 [&_td]:border-gray-300 [&_td]:px-2">
            <thead>
              <tr>
                <th>Email</th>
                <th>Username</th>
                <th className="w-80">Paid</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.email} className="">
                  <td>{user.username}</td>
                  <td>{user.email}</td>
                  <td>
                    <div className="flex items-center justify-center my-1">
                      <label className="switch mb-0 text-2xl">
                        <input
                          type="checkbox"
                          name={user.email}
                          checked={user.isPaid}
                          onChange={() =>
                            handlePaidToggle(user.email, user.isPaid)
                          }
                        />
                        <span className="slider" />
                      </label>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="bg-white/30 p-8 rounded-lg shadow-md shadow-teal-300">
        <form onSubmit={onSubmit}>
          <div className="text-xl tracking-normal text-gray-700">
            Enter details to access the list
          </div>
          <div className="flex flex-col gap-4 mt-6 w-80">
            <input
              required
              autoFocus
              name="username"
              placeholder="Username"
              className="px-3 py-2 rounded-md border-2 border-cyan-700/50"
            />
            <div className="w-full relative select-none">
              <input
                required
                name="password"
                placeholder="Password"
                type={!isPwdVisible ? "password" : "text"}
                className="px-3 py-2 rounded-md w-full border-2 border-cyan-700/50"
              />
              <span
                className="text-gray-700 absolute top-1/2 -translate-y-1/2 right-4 text-xs cursor-pointer"
                onClick={() => setIsPwdVisible(!isPwdVisible)}
              >
                {!isPwdVisible ? "show" : "hide"}
              </span>
            </div>
            <button
              type="submit"
              className="bg-slate-800 text-white py-2 rounded-md"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
