import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import QuizPage from "./pages/QuizPage";
import SignInPage from "./pages/SignInPage";
import LandingPage from "./pages/LandingPage";
import PackagesPage from "./pages/PackagesPage";
import ChildHomePage from "./pages/ChildHomePage";
import ChildSignUpPage from "./pages/ChildSignUpPage";
import YoungAdultSignUp from "./pages/YoungAdultSignUp";
import YoungAdultHomePage from "./pages/YoungAdultHomePage";
import PaidUserControlPage from "./pages/PaidUserControlPage";

import { useAuth } from "./contexts/AuthContext";

const ProtectedRoute = ({ element }) => {
  const { userData } = useAuth();

  return userData?.isAuthenticated ? element : <Navigate to="/SignIn" />;
};

export default function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/SignIn" element={<SignInPage />} />
        <Route path="/SignUp" element={<ChildSignUpPage />} />
        <Route path="/signup-new" element={<YoungAdultSignUp />} />
        <Route path="/paid-users" element={<PaidUserControlPage />} />
        <Route path="/packages" element={<PackagesPage />} />

        <Route
          path="/quiz/:url/:email"
          element={<ProtectedRoute element={<QuizPage />} />}
        />
        <Route
          path="/home"
          element={<ProtectedRoute element={<HomePage />} />}
        />
      </Routes>
    </BrowserRouter>
  );
}

const HomePage = () => {
  const { userData } = useAuth();

  return !userData?.isAdult ? <ChildHomePage /> : <YoungAdultHomePage />;
};
