import { useState } from "react";
import axios from "axios";

const ContactForm = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(`/contact-us`, formData)
      .then(({ data }) => {
        alert(data.message);
        setFormData({
          fullName: "",
          email: "",
          subject: "",
          message: "",
        });
      })
      .catch((e) => alert(e.message))
      .finally(() => setLoading(false));
  };

  return (
    <form onSubmit={onSubmitHandler}>
      <div className="flex flex-col [&_input]:bg-[#F1F1F1] [&_input]:p-3 space-y-4">
        <div className="flex space-x-4 w-full">
          <input
            type="text"
            id="fullName"
            placeholder="Full Name"
            name="fullname"
            className="w-full"
            required
            value={formData.fullName}
            onChange={handleChange}
          />
          <input
            type="email"
            id="email"
            placeholder="Email"
            name="email"
            className="w-full"
            required
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <input
          id="subject"
          placeholder="Subject"
          name="subject"
          required
          value={formData.subject}
          onChange={handleChange}
        />
        <textarea
          id="message"
          name="message"
          rows="7"
          placeholder="Your message here"
          className="w-full bg-[#F1F1F1] border-none"
          required
          value={formData.message}
          onChange={handleChange}
        />
        <div className="pt-10 md:pt-20 self-center">
          <button
            type="submit"
            className="bg-primary text-white w-fit px-6 py-2 rounded-md"
            disabled={loading}
          >
            {loading ? "Submitting..." : "Submit"}
          </button>
        </div>
      </div>
    </form>
  );
};

export default ContactForm;
