import axios from "axios";

import AppRoutes from "./Routes.js";
import { AuthProvider } from "./contexts/AuthContext.js";

import "./App.css";

axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;

const App = () => {
  return (
    <AuthProvider>
      <AppRoutes />
    </AuthProvider>
  );
};

export default App;
