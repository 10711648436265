// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `iframe {
  flex: 1 1;
  border: 0;
}

.proprofs-link {
  font-size: 10px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: left;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,SAAO;EACP,SAAS;AACX;;AAEA;EACE,eAAe;EACf,yCAAyC;EACzC,gBAAgB;AAClB","sourcesContent":["iframe {\n  flex: 1;\n  border: 0;\n}\n\n.proprofs-link {\n  font-size: 10px;\n  font-family: Arial, Helvetica, sans-serif;\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
