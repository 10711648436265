export default function LeaderboardTable({ leaderboardData }) {
  return (
    <div className="leaderboard-container">
      <table className="leaderboard-table">
        <thead>
          <tr>
            <th>Weekly</th>
            <th>Monthly</th>
            <th>All Time</th>
          </tr>
        </thead>
        <tbody>
          {leaderboardData.allTime.map((item, index) => (
            <tr key={index}>
              <td>
                {index + 1}. {leaderboardData.weekly[index]?.username}
                <span className="text-sm">
                  {leaderboardData.weekly[index]?.totalScore
                    ? ` (${Number(Number(leaderboardData.weekly[index]?.totalScore).toFixed(2))})`
                    : ""}
                </span>
              </td>
              <td>
                {index + 1}. {leaderboardData.monthly[index]?.username}
                <span className="text-sm">
                  {leaderboardData.monthly[index]?.totalScore
                    ? ` (${Number(Number(leaderboardData.monthly[index]?.totalScore).toFixed(2))})`
                    : ""}
                </span>
              </td>
              <td>
                {index + 1}. {leaderboardData.allTime[index]?.username}
                <span className="text-sm">
                  {leaderboardData.allTime[index]?.totalScore
                    ? ` (${Number(Number(leaderboardData.allTime[index]?.totalScore).toFixed(2))})`
                    : ""}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
