import { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

import eopen from "../assets/eopen.png";
import avatar from "../assets/avatar.png";
import eclose from "../assets/eclose.png";
import { useAuth } from "../contexts/AuthContext.js";

import "../styles/Screen2.css";

export default function SignInPage() {
  const moveToNext = (event, nextInputId) => {
    if (event.key === "Enter") {
      event.preventDefault();
      document.getElementById(nextInputId)?.focus();
    }
  };

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const { setUserData } = useAuth();
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { data } = await axios.post(`/auth/signin`, formData);

      setUserData({
        ...data.data,
        isAuthenticated: true,
      });

      alert("Login Successful");
      navigate("/home");
    } catch (e) {
      alert(e.response.data.message);
    }
  };

  return (
    <div className="page-container">
      <div className="image-container" />
      <div className="flex items-center justify-between px-10">
        <Link to="/">
          <img
            src={avatar}
            alt="logo"
            className="logo max-lg:mt-3 min-w-12 min-h-12"
          />
        </Link>
        <div className="flex items-center space-x-1 sm:space-x-4 mr-8">
          <div className="text-white max-sm:text-xs max-sm:mt-3 text-xl">
            Sign Up
          </div>
          <Link
            to="/signup-new"
            className="header-signin-button extra-margin text-sm"
          >
            <button className="signInButton px-4 !h-7">Young Adult</button>
          </Link>
          <Link
            to="/SignUp"
            className="header-signin-button extra-margin text-sm"
          >
            <button className="signInButton px-4 !h-7">Kids</button>
          </Link>
        </div>
      </div>
      <div className="signin-grid">
        <div className="page-title">
          <h1>Hello! Digital Warriors</h1>
          <h2>
            Welcome to the <br />
            DigiVerse
          </h2>
        </div>
        <div className="screen2-container">
          <form className="registration-form" onSubmit={handleSubmit}>
            <div>
              <h3 className="m-0">Login</h3>

              <p> Login to access your content</p>
            </div>

            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                onChange={handleChange}
                required
                onKeyUp={(e) => moveToNext(e, "contact")}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                position: "relative",
              }}
            >
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  onChange={handleChange}
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  value={formData.password}
                  required
                />
              </div>
              <img
                src={showPassword ? eopen : eclose}
                style={{
                  width: "20px",
                  height: "20px",
                  position: "absolute",
                  right: "10px",
                }}
                onClick={() => setShowPassword(!showPassword)}
                alt="Toggle Password Visibility"
              />
            </div>

            <div className="form-group-button">
              <button id="SignIn" type="submit" className="px-5">
                Sign In
              </button>
              {/* <Link to="/SignUp">
            <button id="SignUp" >Sign Up</button>
            </Link> */}
            </div>

            <div className="register-link !grid text-center">
              <div className="text-gray-500">Don't have an account?</div>
              <div className="flex items-center text-gray-500 [&>a]:text-black">
                Register for
                <Link
                  to="/signup-new"
                  className="no-underline hover:underline decoration-dotted"
                >
                  <h4 className="ml-1">Young Adult</h4>
                </Link>{" "}
                &nbsp;or
                <Link
                  to="/SignUp"
                  className="no-underline hover:underline decoration-dotted"
                >
                  <h4 className="ml-1">Kids</h4>
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
