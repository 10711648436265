import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import gem from "../assets/gem.png";
import crown from "../assets/crown.png";
import image from "../assets/image.png";
import avatar from "../assets/avatar.png";
import logout from "../assets/logout.png";
import RulesModal from "./components/RulesModal";
import { useAuth } from "../contexts/AuthContext";
import LeaderboardTable from "./components/LeaderboardTable";

import "../styles/home.css";
import { LockKeyhole } from "lucide-react";

const ChildHomePage = () => {
  const { userData, setUserData } = useAuth();
  const points =
    userData?.points?.levelScores?.find(
      (score) => score._id === userData?.currentLevel,
    )?.totalScore ?? 0;
  const username = userData?.username;

  const curLevel = userData?.currentLevel;

  // Load selectedLevel from cookie or default to curLevel
  const [selectedLevel, setSelectedLevel] = useState(() => {
    const savedLevel = Cookies.get("selectedLevel");
    return savedLevel ? parseInt(savedLevel) : curLevel;
  });

  const [leaderboard, setLeaderboard] = useState();
  const [topics, setTopics] = useState();
  const totalScore = userData?.points?.totalScore ?? 0;

  const [rulesModal, setRulesModal] = useState(false);

  const showModal = () => {
    setRulesModal(true);
  };
  const hideModal = () => {
    setRulesModal(false);
  };

  // Save selectedLevel to cookie whenever it changes
  useEffect(() => {
    Cookies.set("selectedLevel", selectedLevel.toString(), { expires: 30 });
  }, [selectedLevel]);

  useEffect(() => {
    // fetch leaderboard data
    (async () => {
      try {
        const lboardData = await axios.post(`/leaderboard`);
        setLeaderboard(lboardData.data);
      } catch (err) {
        console.error("Error fetching values", err);
      }
    })();
  }, []);

  useEffect(() => {
    // fetch topics data
    (async () => {
      try {
        const topicsData = await axios.post(`/topics`, {
          id: userData._id,
        });
        setTopics(topicsData.data);
      } catch (err) {
        console.error("Error fetching values", err);
      }
    })();
  }, []);

  useEffect(() => {
    if (!userData.topic) return;
    // fetch user data
    (async () => {
      try {
        const { data } = await axios.post(`/user`, {
          id: userData._id,
        });
        setUserData(data.data);
      } catch (err) {
        console.error("Error fetching values", err);
      }
    })();
  }, []);

  const handleCurtopic = (topicName) => {
    setUserData({
      topic: topicName,
    });
  };

  const navigate = useNavigate();
  const handleLogout = () => {
    // Clear the selectedLevel cookie on logout
    Cookies.remove("selectedLevel");
    navigate("/");
    window.location.reload();
  };

  return (
    <div className="container">
      <header>
        <div className="header-align">
          <Link to="#">
            <img src={avatar} alt="avatar" className="logo" />
          </Link>
          <h1 className="text-2xl">Digital Hero Zone</h1>
          <div className="logout-btn">
            <h3>Hello Prodigies! {userData.username?.split(" ")?.[0]}</h3>
            <img src={logout} alt="logout" onClick={handleLogout} />
          </div>
        </div>
        <div className="welcome-content">
          <h1 className="heading-mobile">Digital Hero Zone</h1>
          <p className="!text-lg">Welcome to the Digital Battlefield!</p>
          <p className="!text-lg">
            Level up your skills and unlock new challenges as you master the
            digital world
          </p>
        </div>
      </header>
      <div className="grid grid-gap-3">
        <div className="grid-2 grid-space">
          <div className="left-section-section">
            <p>
              This is a huge <b>Digital Library</b> that houses important data &
              protects many secrets which Technologists have been passing
              through generations. A <b>sneaky virus</b> called the Data
              Corrupter is all set to attack this House of Ultimate Digital
              Knowledge and hack well-kept secrets!
            </p>
            <p>
              Captain Diginaut is on her mission to save the library. She needs
              to use all her Digital Powers but it's a race against time &{" "}
              <b>she needs your Help!</b>
            </p>
            <p>
              <b>Play the Quizzes, pass the Levels, win the Gems</b> and clear
              this stage to help Captain Diginaut & become{" "}
              <b>the LEGEND - the Legendary Digital Hero!!</b>
            </p>
          </div>
          <div className="current-status">
            <div className="status-image">
              <img src={image} />
            </div>
          </div>
        </div>
        <div className="grid-2 grid-space">
          <div className="profile">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h2>🌟 Warrior {username}</h2>
              <div style={{ textAlign: "center" }}>
                <img src={crown} style={{ width: "30px", height: "40px" }} />
                <p>{totalScore}</p>
              </div>
            </div>

            <table>
              <tbody>
                <tr style={{ height: "50px" }}>
                  <td>Level</td>
                  <td>
                    <strong>{curLevel}</strong>
                  </td>
                </tr>
                <tr style={{ height: "50px" }}>
                  <td>Points</td>
                  <td>
                    <strong>{points}</strong>
                  </td>
                </tr>
                <tr style={{ height: "50px" }}>
                  <td>Gems</td>
                  <td>
                    <div className="flex items-center">
                      <span>{userData?.gems}</span>
                      <img src={gem} alt="Gem" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{ maxWidth: "400px", margin: "auto" }}>
              <p style={{ fontSize: "20px", margin: "10px 0" }}>
                Continue to play Quizzes and keep clearing the Levels.
              </p>
              <h3 style={{ fontSize: "20px", fontWeight: 500 }}>
                Save the Library of Wisdom and be the Digital Hero.
              </h3>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: 300,
                  marginTop: "20px",
                  cursor: "pointer",
                  textAlign: "left",
                  marginLeft: "-1.1vw",
                }}
              >
                Check <u onClick={showModal}>Rules</u> and get started
              </p>
              {rulesModal && <RulesModal closeModal={hideModal} />}
            </div>
          </div>
          <div>
            <p>
              Dive into fun quizzes to help out your friend Captain Diginaut as
              she takes on the villains of DigiVerse.
              <span style={{ marginBottom: "45px" }} className="block">
                As you play, also learn you awesome Tech Tricks, Digital Good
                Habits & Safety tips!
              </span>
            </p>
            <div className="flex gap-2 justify-center mb-12 text-lg text-white font-normal">
              <div
                className={`border border-black rounded-md px-2 flex items-center justify-center bg-blue-500 gap-2 ${
                  selectedLevel === 1 ? "bg-blue-700" : "cursor-pointer"
                }`}
                onClick={() => setSelectedLevel(1)}
              >
                Level 1
              </div>
              <div
                className={`border border-black rounded-md px-2 flex items-center justify-center bg-blue-500 gap-2 ${
                  userData?.currentLevel < 2 ? "opacity-50" : "cursor-pointer"
                } ${selectedLevel === 2 ? "bg-blue-700" : ""}`}
                onClick={() =>
                  userData?.currentLevel > 1 && setSelectedLevel(2)
                }
              >
                Level 2{" "}
                {userData?.currentLevel < 2 && (
                  <LockKeyhole className="w-4 h-4" />
                )}
              </div>
              <div
                className={`border border-black rounded-md px-2 flex items-center justify-center bg-blue-500 gap-2 ${
                  userData?.currentLevel < 3 ? "opacity-50" : "cursor-pointer"
                } ${selectedLevel === 3 ? "bg-blue-700" : ""}`}
                onClick={() =>
                  userData?.currentLevel > 2 && setSelectedLevel(3)
                }
              >
                Level 3{" "}
                {userData?.currentLevel < 3 && (
                  <LockKeyhole className="w-4 h-4" />
                )}
              </div>
            </div>
            <div className="asidebtn">
              {Array.isArray(topics) &&
                topics.map((topic) => {
                  return (
                    <Link
                      to={`/quiz/${topic.levels[selectedLevel - 1][userData?.isPaid ? "unlimitedQuizUrl" : "quizUrl"]}/${userData?.email}`}
                      key={topic.topicName}
                    >
                      <button
                        id={topic.topicName}
                        value={topic.topicName}
                        onClick={() => handleCurtopic(topic.topicName)}
                      >
                        {topic.topicName}
                      </button>
                    </Link>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="grid-2 grid-space ">
          <div className="leaderboard-content">
            <h3>
              Can you top the leader board and show you're the ultimate digital
              champion
            </h3>
          </div>
          <div className="leaderboard">
            <h1 style={{ marginBottom: "30px" }}>Leaderboard</h1>
            {leaderboard && <LeaderboardTable leaderboardData={leaderboard} />}
          </div>
        </div>
      </div>
      <a
        target="_blank"
        href="https://forms.gle/zNSChvNRubefYaTM6"
        rel="noreferrer"
      >
        <div className="home-container">
          <u>Feedback Form</u>
        </div>
      </a>
    </div>
  );
};

export default ChildHomePage;
