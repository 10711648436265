import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { LockKeyhole } from "lucide-react";
import Cookies from "js-cookie";

import gem from "../assets/gem.png";
import crown from "../assets/crown.png";
import avatar from "../assets/avatar.png";
import logout from "../assets/logout.png";
import RulesModal from "./components/RulesModal";
import { useAuth } from "../contexts/AuthContext";
import PaymentModal from "./components/PaymentModal";
import LeaderboardTable from "./components/LeaderboardTable";

import "../styles/home.css";

export default function YoungAdultHomePage() {
  const { userData, setUserData, clearSession } = useAuth();

  const points =
    userData?.points?.levelScores?.find(
      (score) => score._id === userData?.currentLevel,
    )?.totalScore ?? 0;
  const username = userData?.username;

  const curLevel = userData?.currentLevel;

  // Load selectedLevel from cookie or default to curLevel
  const [selectedLevel, setSelectedLevel] = useState(() => {
    const savedLevel = Cookies.get("selectedLevel");
    return savedLevel ? parseInt(savedLevel) : curLevel;
  });

  const [leaderboard, setLeaderboard] = useState();
  const totalScore = userData?.points?.totalScore ?? 0;

  const [rulesModal, setRulesModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [topics, setTopics] = useState([]);

  const showModal = () => {
    setRulesModal(true);
  };
  const hideModal = () => {
    setRulesModal(false);
  };

  useEffect(() => {
    // fetch leaderboard data
    (async () => {
      try {
        const lboardData = await axios.post(`/leaderboard`);
        setLeaderboard(lboardData.data);
      } catch (err) {
        console.error("Error fetching values", err);
      }
    })();
  }, []);

  useEffect(() => {
    // fetch topics data
    (async () => {
      try {
        const topicsData = await axios.post(`/topics`, {
          id: userData._id,
        });
        setTopics(topicsData.data);
      } catch (err) {
        console.error("Error fetching values", err);
      }
    })();
  }, []);

  useEffect(() => {
    if (!userData.topic) return;
    // fetch user data
    (async () => {
      try {
        const { data } = await axios.post(`/user`, {
          id: userData._id,
        });
        setUserData(data.data);
      } catch (err) {
        console.error("Error fetching values", err);
      }
    })();
  }, []);

  // Save selectedLevel to cookie whenever it changes
  useEffect(() => {
    Cookies.set("selectedLevel", selectedLevel.toString(), { expires: 30 });
  }, [selectedLevel]);

  const handleCurtopic = (topicName) => {
    setUserData({
      topic: topicName,
    });
  };

  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear the selectedLevel cookie on logout
    Cookies.remove("selectedLevel");
    navigate("/", { replace: true });
    clearSession();
  };

  return (
    <div className="container">
      <header>
        <div className="header-align">
          <Link to="#">
            <img src={avatar} alt="avatar" className="logo" />
          </Link>
          <h1 className="text-2xl">Digital Hero Zone</h1>
          <div className="logout-btn">
            <h3>Hello Young Adult! {userData.username?.split(" ")?.[0]}</h3>
            <img src={logout} alt="logout" onClick={handleLogout} />
          </div>
        </div>
        <div className="welcome-content">
          <h1 className="heading-mobile">Digital Hero Zone</h1>
          <p className="!text-lg">Welcome to the Digital Battlefield!</p>
          <p className="!text-lg">
            Level up your skills and unlock new challenges as you master the
            digital world
          </p>
        </div>
      </header>
      <div className="overflow-hidden bg-[#253439] text-white px-2 py-1 w-screen absolute left-0">
        <div
          className="whitespace-nowrap inline-block animate-[marquee_20s_linear_infinite] font-normal text-base"
          style={{ animation: "marquee 20s linear infinite" }}
        >
          Grab your chance to win cool prizes: Play the Quiz, Ace the Challenge
          and be in the Top Three of the leaderboard at the end of the month.
        </div>
      </div>
      <div className="w-full h-[42px]" />
      <div className="grid grid-gap-3">
        <div className="grid-2 grid-space mt-4">
          <div className="profile">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h2>🌟 Warrior {username}</h2>
              <div style={{ textAlign: "center" }}>
                <img src={crown} style={{ width: "30px", height: "40px" }} />
                <p>{totalScore}</p>
              </div>
            </div>

            <table>
              <tbody>
                <tr style={{ height: "50px" }}>
                  <td>Level</td>
                  <td>
                    <strong>{curLevel}</strong>
                  </td>
                </tr>
                <tr style={{ height: "50px" }}>
                  <td>Points</td>
                  <td>
                    <strong>{points}</strong>
                  </td>
                </tr>
                <tr style={{ height: "50px" }}>
                  <td>Gems</td>
                  <td>
                    <div className="flex items-center">
                      <span>{userData?.gems}</span>
                      <img src={gem} alt="Gem" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{ maxWidth: "400px", margin: "auto" }}>
              <p style={{ fontSize: "20px", margin: "10px 0" }}>
                Continue to play Quizzes and keep clearing the Levels.
              </p>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: 300,
                  marginTop: "20px",
                  cursor: "pointer",
                  textAlign: "left",
                  marginLeft: "-1.1vw",
                }}
              >
                Check <u onClick={showModal}>Rules</u> and get started
              </p>
              {rulesModal && <RulesModal closeModal={hideModal} />}
            </div>
          </div>
          <div>
            <p>
              Dive into fun quizzes to help out your friend Captain Diginaut as
              she takes on the villains of DigiVerse.
              <span style={{ marginBottom: "45px" }} className="block">
                As you play, also learn you awesome Tech Tricks, Digital Good
                Habits & Safety tips!
              </span>
            </p>
            <div className="flex gap-2 justify-center mb-12 text-lg text-white font-normal">
              <div
                className={`border border-black rounded-md px-2 flex items-center justify-center bg-blue-500 gap-2 ${
                  selectedLevel === 1 ? "bg-blue-700" : "cursor-pointer"
                }`}
                onClick={() => setSelectedLevel(1)}
              >
                Level 1
              </div>
              <div
                className={`border border-black rounded-md px-2 flex items-center justify-center bg-blue-500 gap-2 ${
                  userData?.currentLevel < 2 ? "opacity-50" : "cursor-pointer"
                } ${selectedLevel === 2 ? "bg-blue-700" : ""}`}
                onClick={() =>
                  userData?.currentLevel > 1 && setSelectedLevel(2)
                }
              >
                Level 2{" "}
                {userData?.currentLevel < 2 && (
                  <LockKeyhole className="w-4 h-4" />
                )}
              </div>
              <div
                className={`border border-black rounded-md px-2 flex items-center justify-center bg-blue-500 gap-2 ${
                  userData?.currentLevel < 3 ? "opacity-50" : "cursor-pointer"
                } ${selectedLevel === 3 ? "bg-blue-700" : ""}`}
                onClick={() =>
                  userData?.currentLevel > 2 && setSelectedLevel(3)
                }
              >
                Level 3{" "}
                {userData?.currentLevel < 3 && (
                  <LockKeyhole className="w-4 h-4" />
                )}
              </div>
            </div>
            <div className="asidebtn">
              {Array.isArray(topics) &&
                topics.map((topic) => {
                  return (
                    <Link
                      to={`/quiz/${topic.levels[selectedLevel - 1][userData?.isPaid ? "unlimitedQuizUrl" : "quizUrl"]}/${userData?.email}`}
                      key={topic.topicName}
                    >
                      <button
                        id={topic.topicName}
                        value={topic.topicName}
                        onClick={() => handleCurtopic(topic.topicName)}
                      >
                        {topic.topicName}
                      </button>
                    </Link>
                  );
                })}
            </div>
            {!userData?.isPaid ? (
              <div className="text-lg max-w-[28rem] mx-auto mt-12">
                Play More, Learn More & Win More!
                <br />
                Climb the Leaderboard & Grab Prizes!
                <br />
                Continue to play unlimited number of Quizzes @ Rs99/- only.{" "}
                <span
                  className="underline cursor-pointer"
                  onClick={() => setPaymentModal(true)}
                >
                  Click here
                </span>
              </div>
            ) : (
              <div className="text-lg max-w-[28rem] mx-auto mt-12 font-normal">
                Thank you for being our premium user, we appreciate our
                relationship with you.
                <br />
                Please let us know in case of any concerns through{" "}
                <a
                  target="_blank"
                  href="https://forms.gle/zNSChvNRubefYaTM6"
                  rel="noreferrer"
                >
                  <u className="font-bold">feedback form</u>
                </a>{" "}
                or contacting us on our official id:{" "}
                <a
                  href="mailto:for.ehte@gmail.com"
                  className="underline cursor-pointer font-bold"
                >
                  for.ehte@gmail.com
                </a>
              </div>
            )}
          </div>
        </div>
        <div className="grid-2 grid-space mt-12">
          <div className="leaderboard-content">
            <h3>
              Can you top the leader board and show you're the ultimate digital
              champion?
            </h3>
            <div className="text-lg mt-10 font-normal">
              <div>
                Challenge your friend & help us in spreading Digital Awareness.
              </div>
              <div>
                Share your{" "}
                <b className="text-black">
                  Referral Code ({userData?.referralCode})
                </b>{" "}
                with your friends
              </div>
              <div>
                If your referrals sign up using referral code, you get 50 bonus
                points when they play their First Quiz. This will be counted
                towards Leader Board points.
              </div>
            </div>
          </div>
          <div className="leaderboard">
            <div className="text-3xl mb-5">Leaderboard</div>
            <p className="text-base mb-4 px-4 font-normal">
              Top 3 in Monthly Leader Board get Amazon Gift Vouchers as Prize,
              at end of each month. Fill the{" "}
              <a
                target="_blank"
                href="https://forms.gle/zNSChvNRubefYaTM6"
                rel="noreferrer"
              >
                <u className="font-bold">feedback form</u>
              </a>{" "}
              at the bottom be eligible for the award.
            </p>
            {leaderboard && <LeaderboardTable leaderboardData={leaderboard} />}
          </div>
        </div>
      </div>
      <a
        target="_blank"
        href="https://forms.gle/zNSChvNRubefYaTM6"
        rel="noreferrer"
      >
        <div className="home-container">
          <u>Feedback Form</u>
        </div>
      </a>
      {paymentModal && (
        <PaymentModal closeModal={() => setPaymentModal(false)} />
      )}
    </div>
  );
}
