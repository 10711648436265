import Slider from "./Slider";
import people1 from "../assets/home/people-1.png";
import people2 from "../assets/home/people-2.png";
import people3 from "../assets/home/people-3.png";

const HeroSection = () => {
  return (
    <a href="/signup-new" className="block no-underline">
      <div className="grid md:grid-cols-[1fr_2.2fr] bg-white text-gray-800 md:py-3 md:pl-4 relative">
        <div className="absolute left-0 top-0 bg-primary-purple h-full w-[260px] hidden md:block" />
        <div className="overflow-hidden bg-[#253439] text-white px-2 py-1 md:hidden">
          <div
            className="whitespace-nowrap inline-block animate-[marquee_20s_linear_infinite]"
            style={{ animation: "marquee 20s linear infinite" }}
          >
            Grab your chance to win cool prizes: Play the Quiz, Ace the
            Challenge and be in the Top Three of the leaderboard at the end of
            the month.
          </div>
        </div>
        {/* Slider on the Left */}
        <Slider />
        {/* Content on the Right */}
        <div className="py-2 md:pl-6 text-center lg:text-left">
          <h1 className="text-xl md:text-2xl xl:text-3xl font-normal mb-2 mt-0">
            IS YOUR AGE BETWEEN <span className="block md:inline">18–25?</span>
          </h1>
          <div className=" p-4" style={{ backgroundColor: "#253439" }}>
            <div className="flex">
              <div className="w-8/12 md:w-9/12">
                <h3 className="text-lg md:text-xl font-semibold text-center text-white mb-2">
                  ATTENTION YOUNG ADULTS
                </h3>
                <h2 className="text-white text-xl md:xl xl:text-2xl font-semibold">
                  YOUR DIGITAL LIFE NEEDS A HERO. IS THAT YOU?
                </h2>
              </div>
              <div className="w-4/12 md:w-3/12 flex">
                <img
                  src={people1}
                  alt="peoples"
                  className="w-[70px] -ml-0 -mt-8 object-contain rotate-12"
                />
                <img
                  src={people2}
                  alt="peoples"
                  className="w-[70px] object-contain -ml-8 md:-ml-6 xl:ml-0 max-sm:mt-20"
                />
              </div>
            </div>
          </div>
          <div className="grid md:grid-cols-[1fr_1.4fr] gap-4 mb-4">
            <p className="font-light text-lg xl:text-lg p-3">
              Our Mission is to Build a Safer and Informed Digital Future for
              all
            </p>
            <div className="bg-gray-300 p-3">
              <p className="font-normal text-lg  uppercase">
                play our tech-punch Quizzes
              </p>

              <h3 className="font-semibold text-lg  uppercase">
                stand a chance to win gifts
              </h3>
            </div>
          </div>
          <div className="max-sm:p-4 flex gap-5">
            <div className="w-3/12 md:w-2/12 ml-4">
              <img
                src={people3}
                alt="peoples"
                className="w-[100px] h-[100px] object-contain"
              />
            </div>
            <div className="w-9/12 md:w-10/12">
              <h3 className="text-xl font-bold text-center text-gray-700 mb-2">
                LEARN TIPS & TRICKS TO USE TECHNOLOGY BETTER
                <br />
                LEARN HOW TO BE SAFE ONLINE
              </h3>
              <button className="bg-purple-700 text-white px-6 py-2 rounded-full hover:bg-purple-800 transition">
                PLAY OUR QUIZZES
              </button>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

export default HeroSection;
