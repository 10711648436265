const RulesModal = ({ closeModal }) => {
  return (
    <div className="modal-container z-50">
      <div onClick={closeModal} className="modal-overlay">
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="modal-header text-center">Rules of the Game</div>

          <hr style={{ marginBottom: "20px", marginTop: "10px" }} />

          <div style={{ textAlign: "left" }}>
            <h4>Objective of the Game</h4>
            <p style={{ fontSize: "14px", fontWeight: 300 }}>
              Solve the Challenge by playing Quizzes in each topic. Score
              points, clear Levels and earn GEMs. Once you clear all the three
              Levels and earn GEM in each topic, you become the{" "}
              <b>Legendary Digital Hero</b> who has helped Captain Diginaut in
              Saving the Library of Wisdom.
              {/* {term.points && (
                  <ul>
                    {term.points.map((point) => (
                      <li key={point}>{point}</li>
                    ))}
                  </ul>
                )} */}
            </p>
          </div>
          <div style={{ textAlign: "left" }}>
            <h4>How to Play</h4>
            <p style={{ fontSize: "14px", fontWeight: 300 }}>
              <ol>
                <li style={{ marginTop: "15px" }}>
                  <h5 style={{ fontSize: "16px" }}>Take On the Challenge:</h5>
                  The Challenge consists of three Levels. In each level, you
                  will have access to solve <b>one Quiz from each topic</b>.
                  Each Quiz needs to be completed within a <b>time limit</b>{" "}
                  —answer quickly, but wisely! As you move to higher Levels, the
                  Question types & the Complexity <b>increases</b>. Across the
                  three Level of the Challenge, you will come across{" "}
                  <b>different Question types</b> that pose different set of
                  complexities. The Question types include True/ False, Multiple
                  Choice (Single & multi-Select), Matching, Order List, Video or
                  Audio based Questions.
                </li>
                <li style={{ marginTop: "15px" }}>
                  <h5 style={{ fontSize: "16px" }}>Score Points:</h5>
                  Each correct answer gives you <b>10 Points</b>. For questions
                  that have multiple answers, you also get partial marks.
                  There’s <b>no negative marking</b>, so take on every challenge
                  with confidence! Your Points in each Level will add as you
                  complete each quiz, bringing you closer to completing the
                  challenge.
                </li>
                <li style={{ marginTop: "15px" }}>
                  <h5 style={{ fontSize: "16px" }}>Earn Gems:</h5>
                  If you score <b>70 or more</b> in a quiz, you earn a GEM in
                  that topic.
                </li>
                <li style={{ marginTop: "15px" }}>
                  <h5 style={{ fontSize: "16px" }}>Progress to Next Level:</h5>
                  In each Level, as you attempt a Quiz, your points continue to
                  add up for that Level. You need to score at least <b>
                    240
                  </b>{" "}
                  in total to clear the level. Once you move to the Next Level,
                  your points for the new Level starts at 0 again.
                </li>
                <li style={{ marginTop: "15px" }}>
                  <h5 style={{ fontSize: "16px" }}>Complete the Mission:</h5>
                  You must <b>clear</b> all the three Levels. You must{" "}
                  <b>earn</b> a GEM in each topic.
                </li>
              </ol>
            </p>
          </div>
          <div
            className="header-signup-button"
            style={{ textAlign: "center" }}
            onClick={closeModal}
          >
            <button
              className="signInButton"
              style={{
                marginLeft: "auto",
                fontSize: "14px",
                padding: "0 20px",
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RulesModal;
