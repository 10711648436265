import { createContext, useContext, useState, useEffect } from "react";
import Cookies from "js-cookie";

const Context = createContext();

export const AuthProvider = ({ children }) => {
  // Initialize userData from cookie or set to default
  const [userData, setUserData] = useState(() => {
    const savedUserData = Cookies.get("userData");
    return savedUserData
      ? JSON.parse(savedUserData)
      : { isAuthenticated: false };
  });

  // Update cookie whenever userData changes
  useEffect(() => {
    if (userData) {
      Cookies.set("userData", JSON.stringify(userData), { expires: 1 }); // expires in 1 day (24 hours)
    }
  }, [userData]);

  const updateUserData = (user) => {
    setUserData({
      ...userData,
      ...user,
    });
  };

  const clearSession = () => {
    setUserData({
      isAuthenticated: false,
    });
    Cookies.remove("userData", { path: "/" });
  };

  return (
    <Context.Provider
      value={{ userData, setUserData: updateUserData, clearSession }}
    >
      {children}
    </Context.Provider>
  );
};

export const useAuth = () => useContext(Context);
