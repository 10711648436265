import PaymentQR from "../../assets/PaymentQR.png";

const PaymentModal = ({ closeModal }) => {
  return (
    <div className="modal-container">
      <div onClick={closeModal} className="modal-overlay">
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="modal-header text-center">
            Score More & Reach New Heights
          </div>

          <hr style={{ marginBottom: "20px", marginTop: "10px" }} />
          <p className="text-lg max-w-[32rem] mx-auto">
            Make the payment and send screenshot of your payment to the{" "}
            <a
              href="mailto:for.ehte@gmail.com"
              className="underline cursor-pointer font-bold"
            >
              for.ehte@gmail.com
            </a>
            . Once verified, your unlimited quizzes will be unlocked.
          </p>

          <div className="text-center">
            <img
              src={PaymentQR}
              alt="payment QR"
              className="w-[30rem] mx-auto"
            />
          </div>
          <div
            className="header-signup-button"
            style={{ textAlign: "center" }}
            onClick={closeModal}
          >
            <button
              className="signInButton"
              style={{
                marginLeft: "auto",
                fontSize: "14px",
                padding: "0 20px",
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentModal;
